import { ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// components
import { FirstStep, SecondStep } from '..'

// hooks
import { useReducerState } from '../../hooks'
import FinishModal from '../FinishModal'
import { parseStringToBoolean } from '../../../../utils/misc'

const enablePortfolioPurchase = process.env.REACT_APP_PORTFOLIO_PURCHASE_ENABLE as 'false' | 'true'

const EntryModule = (): ReactElement => {
    const [step] = useReducerState()
    const history = useHistory()
    const stateAuth = useSelector((stateRef: any) => stateRef.auth)

    useEffect(() => {
        if (!(stateAuth.portfolioPurchase && parseStringToBoolean(enablePortfolioPurchase)))
            history.push('/home-wallet')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {
                {
                    1: <FirstStep />,
                    2: <SecondStep />,
                    3: <FinishModal />,
                }[step.currentStep]
            }
        </>
    )
}

export default EntryModule
