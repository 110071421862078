/**
 * Action types
 */

export const GET_TRANSACTION = {
    REQUEST: 'GET_TRANSACTION/request',
    SUCCESS: 'GET_TRANSACTION/success',
    FAILURE: 'GET_TRANSACTION/failure',
} as const

export const CREATE_TRANSACTION = {
    REQUEST: 'CREATE_TRANSACTION/request',
    SUCCESS: 'CREATE_TRANSACTION/success',
    FAILURE: 'CREATE_TRANSACTION/failure',
    FILL: 'CREATE_TRANSACTION/FILL',
    CLEAN: 'CREATE_TRANSACTION/clean',
} as const

export const SEND_ATTACHMENT_TRANSACTION = {
    REQUEST: 'SEND_ATTACHMENT_TRANSACTION/request',
    SUCCESS: 'SEND_ATTACHMENT_TRANSACTION/success',
    FAILURE: 'SEND_ATTACHMENT_TRANSACTION/failure',
    FILL: 'SEND_ATTACHMENT_TRANSACTION/FILL',
} as const
